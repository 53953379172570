<template>
  <ul
    class="nav nav-pills mb-3 justify-content-center"
    id="pills-tab"
    role="tablist"
  >
    <li class="nav-item" role="presentation">
      <a
        class="nav-link active"
        id="pills-home-tab"
        data-toggle="pill"
        href="#pills-home"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        >Transfer History</a
      >
    </li>
    <li class="nav-item" role="presentation">
      <a
        class="nav-link"
        id="pills-profile-tab"
        data-toggle="pill"
        href="#pills-profile"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        >Payroll History</a
      >
    </li>
  </ul>
  <hr />

  <div class="tab-content" id="v-pills-tabContent">
    <div
      class="tab-pane fade show active pb-0"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="info-detail pb-0">
        <div class="row mt-2 pb-3" style="padding: 0px 68px 0px">
          <div class="col-md-12 text-right">
            <div
              v-if="isEditable"
              class="btn btn-save"
              data-toggle="modal"
              data-target="#modalTransfer"
              style="cursor: pointer"
            >
              <img src="/img/icon-transfer.svg" alt="" class="mr-2" />Transfer
            </div>
          </div>
        </div>
        <div class="timeline pb-0">
          <div
            v-for="(value, index) in list"
            :key="index"
            :class="
              value.status === 'aktif'
                ? 'container right'
                : index === 0
                ? 'container right resign'
                : 'container right disable'
            "
          >
            <div
              :class="
                value.status === 'aktif'
                  ? 'circle'
                  : index === 0
                  ? 'circle-disable resign'
                  : 'circle-disable'
              "
            ></div>
            <div
              :class="
                value.status === 'aktif'
                  ? 'content position-aktif'
                  : index === 0
                  ? 'content resign'
                  : 'content'
              "
            >
              <div
                class="d-flex align-items-center mb-2"
                v-if="value.status === 'aktif' || index != 0"
              >
                <div class="text">Tanggal Masuk</div>
                <!-- <div class="text" v-else-if="index === 0">Tanggal </div> -->
                <div class="value">
                  <span class="mr-4">:</span
                  >{{ formatDateDay(value.tgl_mulai) }}
                </div>
              </div>

              <div
                class="d-flex align-items-center mb-2"
                v-if="value.tgl_selesai"
              >
                <div class="text">Tanggal Keluar</div>
                <div class="value">
                  <span class="mr-4">:</span
                  >{{ formatDateDay(value.tgl_selesai) }}
                </div>
              </div>

              <div class="d-flex align-items-center mb-2">
                <div class="text">Departemen</div>
                <div class="value">
                  <span class="mr-4">:</span>{{ value.divisi }}
                </div>
              </div>

              <div class="d-flex align-items-center mb-2">
                <div class="text">Jabatan</div>
                <div class="value">
                  <span class="mr-4">:</span>{{ value.jabatan }}
                </div>
              </div>

              <div class="d-flex align-items-center mb-2">
                <div class="text">Status Karyawan</div>
                <div class="value">
                  <span class="mr-4">:</span>{{ value.status_karyawan }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-2" v-if="value.alasan">
                <div class="text" v-if="index == 0 && value.status == 'tidak'">
                  Alasan {{ status_Employee == 'diberhentikan' ? 'Diberhentikan' : 'Resign' }}
                </div>
                <div class="text" v-else>Alasan Transfer</div>
                <div class="value">
                  <span class="mr-4">:</span>{{ value.alasan }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
      style="padding: 50px"
    >
      <div class="table-responsive">
        <table
          class="table table-bordered table-striped table-hover"
          id="tablePayrollEmployee"
        >
          <thead>
            <tr>
              <th style="width: 30%">ID Transaksi</th>
              <th style="width: 40%">Tanggal</th>
              <th style="width: 20%">Kategori</th>
              <th style="width: 30%">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(value, index) in list_payroll"
              :key="index"
              v-on:click="detail(value.id)"
              data-toggle="tooltip"
              data-placement="top"
              title="Klik untuk melihat detail"
            >
              <td>{{ value.id }}</td>
              <td>{{ formatDate(value.tanggal_bayar) }}</td>
              <td
                :class="{
                  'text-uppercase': value.kategori == 'thr',
                  'text-capitalize':
                    value.kategori == 'gaji' ||
                    value.kategori == 'bonus' ||
                    value.kategori == 'man power',
                }"
              >
                {{ value.kategori }}
              </td>
              <td class="text-right">{{ formatMoney(value.gaji_bayar) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "HistoryEmployee",
  components: {
    // FooterDashboard
    // Select2,
    // Datepicker
    // TableLite
  },

  props: {
    statusEmployee: String,
    listTransfer: Object,
    isEditable: Boolean,
    listPayroll: Object,
  },

  data() {
    return {
      list: this.$props.listTransfer,
      list_payroll: this.$props.listPayroll,
      tablePayrollEmployee: "",
      status_Employee: this.$props.statusEmployee
    };
  },

  mounted() {
    this.tablePayrollEmployee = $("#tablePayrollEmployee").DataTable({
      info: false,
      order: [],
    });

    setTimeout(() => {
      $("#pills-home-tab").tab("show");
      $("#pills-home").addClass("active show");
    }, 600);

    this.tooltip();
  },

  beforeUnmount() {
    if (this.tablePayrollEmployee) {
      this.tablePayrollEmployee.destroy();
      this.tablePayrollEmployee = "";
    }
  },

  methods: {
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    formatDateDay(string) {
      return moment(string).format("dddd, DD MMMM YYYY");
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.$router.push({
        name: "DetailPayrollEmployee",
        params: {
          id_karyawan: this.$route.params.id,
          id_payroll: id,
        },
      });
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    showModal() {
      $("#modalTransfer").show("modal");
      console.log("asdasd");
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },

  watch: {
    listTransfer: function (newVal) {
      this.list = newVal;
    },
    statusEmployee: function (newVal) {
      this.status_Employee = newVal;
    },
    listPayroll: function (newVal) {
      this.list_payroll = newVal;
      if (this.tablePayrollEmployee) {
        this.tablePayrollEmployee.destroy();
      }
      setTimeout(() => {
        this.tablePayrollEmployee = $("#tablePayrollEmployee").DataTable({
          info: false,
          order: [],
        });
      }, 500);
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.disable .content .text,
.disable .content .value {
  color: #898989;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 14px 13px;
}

.nav-item a {
  color: #898989 !important;
}

.nav-pills .nav-item .nav-link.active {
  color: #68caf8 !important;
}

.avatar img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
}

.green-bedge {
  width: 100px;
}

.red-bedge {
  width: 120px;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.col-left {
  padding: 80px 0px;
  border: 1px solid #eceff1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}

.col-right {
  border: 1px solid #eceff1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  width: 190px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

hr {
  margin: 0px;
  padding: 0px;
}

.row {
  /* border: 1px solid #ECEFF1; */
  border-radius: 10px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 40px;
}

ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav-pills {
  margin: auto;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.position-aktif {
  border: 1px solid #68caf8 !important;
}

.card {
  padding: 63px 50px;
  widows: 100%;
}

.status-aktif {
  position: absolute;
  right: -1px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background: #68caf8;
  color: #fff;
  padding: 8px 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 10px;
}

.circle {
  background: #fff;
  border: 2px solid #68caf8;
  border-radius: 16px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 0;
  z-index: 2;
}

.circle::before {
  content: "";
  position: absolute;
  width: 52px;
  height: 2px;
  top: calc(50% - 1px);
  left: 14px;
  border: 1px #eceff1;
  border-style: dashed;
  z-index: 1;
}

.circle::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  left: -0.5px;
  top: -0.5px;
  background: #68caf8;
  border: 3px #fff solid;
  border-radius: 10px;
  z-index: 1;
}

.circle-disable {
  background: #fff;
  border: 2px solid #b2b2b2;
  border-radius: 16px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 0;
  z-index: 2;
}
.circle-disable.resign {
  border-color: #ff3d3d;
}

.circle-disable::before {
  content: "";
  position: absolute;
  width: 52px;
  height: 2px;
  top: calc(50% - 1px);
  left: 14px;
  border: 1px #eceff1;
  border-style: dashed;
  z-index: 1;
}

.circle-disable::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  left: -0.5px;
  top: -0.5px;
  background: #b2b2b2;
  border: 3px #fff solid;
  border-radius: 10px;
  z-index: 1;
}

.circle-disable.resign::after {
  background: #ff3d3d;
}

/* .circle::after {
       content: '';
        position: absolute;
        width: 2px;
        height: 100px;
        top: 0;
        border: 1px #000;
        border-style: dashed;
        bottom: 0;
        margin-left: -1px;
    } */

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  /* padding: 15px 0; */
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  top: 0;
  border: 1px #eceff1;
  border-style: dashed;
  bottom: 0;
  left: 8px;
  margin-top: 140px;
  margin-left: -1px;
  z-index: 1;
}

.container {
  padding: 15px 67px;
  position: relative;
  background: inherit;
}

.container.left {
  left: 0;
}

/* .container::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        right: -8px;
        background: #ffffff;
        border: 2px solid #006E51;
        border-radius: 16px;
        z-index: 1;
    } */

.container.right::after {
  left: -8px;
}

/* .container::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 2px;
        top: calc(50% - 1px);
        right: 8px;
        background: #006E51;
        z-index: 1;
    } */

.container.right::before {
  left: 8px;
}

.container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006e51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.container.left .date {
  right: -75px;
}

.container.right .date {
  left: -75px;
}

.container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #f6d155;
  border: 2px solid #006e51;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #006e51;
  z-index: 1;
}

.container.left .icon {
  right: 56px;
}

.container.right .icon {
  left: 56px;
}

.container .content {
  padding: 30px 90px 30px 30px;
  background: #fff;
  border: #eceff1 1px solid;
  position: relative;
  border-radius: 0 500px 500px 0;
}

.container .content.resign {
  border-color: #ff3d3d;
}

.container.right .content {
  padding: 30px 30px 30px 90px;
  position: relative;
  border-radius: 10px;
}

.container .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #006e51;
}

.container .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .container {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .container.right {
    left: 0%;
  }

  .container.left::after,
  .container.right::after {
    left: 82px;
  }

  .container.left::before,
  .container.right::before {
    left: 100px;
    border-color: transparent #006e51 transparent transparent;
  }

  .container.left .date,
  .container.right .date {
    right: auto;
    left: 15px;
  }

  .container.left .icon,
  .container.right .icon {
    right: auto;
    left: 146px;
  }

  .container.left .content,
  .container.right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}
</style>
